import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import FormulaBuilder from "../FormulaBuilder/FormulaBuilder";

const Test = () => {
  const [token, setToken] = useState(null);
  const { fId, formulaT } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const server = 'localhost'; // Use 'localhost' or 'herbdev1'
//
  const APIURL = searchParams.get("APIURL") || 
    (server === 'localhost' 
      ? 'http://localhost/practice-api/formula-api' 
      : 'https://api.herbmanager.com/formula-api');

  const DISPENSARY_TOKEN = searchParams.get("DISPENSARY_TOKEN") || "46e3c95a8b75e2503c"; // [46e3c95a8b75e2503c]-herbDev1/local[4ea1ad7cd4cfa86595]-herbManager

  const BASEURL = searchParams.get("BASEURL") || 
    (server === 'localhost' 
      ? 'http://localhost/multidispensary/user/' 
      : 'https://herbdispenser.herbmanager.com');

  const IFRAMEURL = 'https://herbdispenser.herbmanager.com/?page=details&';

  const prac = searchParams.get("PRACID") || "100";
  //const id = searchParams.get("FORMULA_BUILDER_ID") || fId;
  const is_order = searchParams.get("IS_ORDER") === "true";
  const PANEL = searchParams.get("PANEL") || ""; // blank or admin
  const formula = fId;
  const id = formulaT;

  const [eventData, setEventData] = useState(null);

  return (
    <>
      <pre>{JSON.stringify([{ id, prac, formula, is_order, DISPENSARY_TOKEN, APIURL, BASEURL,PANEL }, searchParams], null, 2)}</pre>
      <pre>{JSON.stringify(formula, null, 2)}</pre>
      {/* <div>Token: {token ? "Received ✅" : "Waiting..."}</div> */}
      
      {/* Correctly passing props */}
      <FormulaBuilder {...{ id, prac, formula, is_order, DISPENSARY_TOKEN, APIURL, BASEURL, PANEL }} /> 
    </>
  );
};

export default Test;
