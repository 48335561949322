//import Form from 'react-bootstrap/Form'
import he from 'he' // Import the HTML entity decoder library
import React, { useEffect, useState } from 'react'
import { Form, Input, InputGroup, SelectPicker } from 'rsuite'
import FormulaSectionTextBox from '../helpers/TextBox'
const ErrorMessage = ({ children }) => (
  <>
    <Form.HelpText style={{ color: 'red' }}>{children}</Form.HelpText>
    {/* <span style={{ color: 'red' }}>{children}</span> */}
  </>
)
const decodeEntities = (html) => {
  const doc = new DOMParser().parseFromString(he.decode(html), 'text/html')
  return doc.documentElement.textContent
}

export const FormulaBuilderWithoutTagPreprationInstructionsSection = ({
  formulaBuilderId,
  data,
  display_name,
  onChange,
  ApplyFormValidation,
  thisFormData,
  formulaDeatils,
  fontFamily, titleDisplayName,formulaTextBox
}) => {
  const [seletedVariavle, setSeletedVariable] = useState([])
  const [allvarivale, setAllVariable] = useState([])
  const [selectedTag, setselectedTag] = useState(0)
  const [selectedVariables, setselectedVariables] = useState([])
  const [selectedText, setselectedText] = useState('')
  const [selectedPreview, setselectedPreview] = useState('')
  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({})
  var requirements = [
    {
      column: 'blank_preparation_instructions',
      name: 'Without Tag Preparation Instructions',
      is_required: 0,
      key: 'blank_preparation_instructions',
      allow_zero: false,
    },
    {
      column: 'blank_preparation_instructions_preview',
      name: 'Without Tag Preparation Instructions Preview',
      is_required: 0,
      key: 'blank_preparation_instructions_preview',
      allow_zero: false,
    },
  ]

  useEffect(() => { }, [formulaBuilderId, formulaDeatils])

  useEffect(() => {
    var tempAllVariable = []
    var blankPreparationInstruction = data.blank_preparation_instructions
      ? data.blank_preparation_instructions
      : []
    var temp = [{ id: '', title: `Choose ${titleDisplayName}` }]
    blankPreparationInstruction &&
      blankPreparationInstruction.map((data, index) => {
        var pattern = /\[(.*?)\]/g
        var matches = data.text.match(pattern)
        let variableArr = []
        if (matches) {
          matches.map((v, k) => {
            var variableName = v.trim()
            variableName = variableName.replace(/\[|\]/g, '')
            variableName = variableName.trim()
            var variable = variableName.toLowerCase()
            variable = variable.replace(/\s+/g, '_')
            const field = {
              id: data.id + '_' + k,
              field: variable,
              name: variableName,
              replace: v.trim(),
              value: '',
            }
            variableArr.push(field)
          })
          data.variableArr = variableArr
        }
        data.seleted = 0
          data.previewText = ''
          temp.push(data)
      })
    setAllVariable(temp)
    tempAllVariable = temp

    if (formulaDeatils && formulaDeatils.id) {
      // var blank_preparation_instructions =
      //   formulaDeatils.blank_preparation_instructions
      //     ? JSON.parse(formulaDeatils.blank_preparation_instructions)
      //     : [] 
      function safeJSONParse(jsonString, fallback = {}) {
        try {
            // Decode HTML entities
            let decodedString = jsonString.replace(/&quot;/g, '"')
                                          .replace(/&amp;/g, '&')
                                          .replace(/\\\//g, '/') // Fix escaped slashes
                                          .replace(/\\u00a7/g, '§'); // Handle special chars if needed
            return JSON.parse(decodedString);
        } catch (error) {
            console.error("Invalid JSON:", error);
            return fallback;
        }
    }
    
    // Usage:
    var blank_preparation_instructions = formulaDeatils.blank_preparation_instructions
        ? safeJSONParse(formulaDeatils.blank_preparation_instructions, { variableArr: [], text: "" })
        : { variableArr: [], text: "" };
        
      if (blank_preparation_instructions && blank_preparation_instructions.id) {
        var selectedTag = blank_preparation_instructions.id
        HandelTags(selectedTag)
        const array = tempAllVariable.find((item) => item.id == selectedTag)

        if (
          selectedTag != '' &&
          selectedTag != 0 &&
          array &&
          array.lenght != 0
        ) {
          array.tag_selected = selectedTag
          array.variableText = array.text
          /////////////////////////////////////////////
          var selectedVaribales = blank_preparation_instructions.variableArr
          var selectedPrewiew = blank_preparation_instructions.text
          if (selectedVaribales && selectedVaribales.length != 0) {
            setTimeout(() => {
              selectedVaribales.map((option, index) => {
                HandelTagVariables(
                  selectedTag,
                  option.field,
                  index,
                  option.value,
                  array
                )
              })
              setTimeout(() => {
                HandelPreviewText(selectedTag, array, selectedPrewiew)
              }, 500)
            }, 500)
          }
        }
      }else{
        var selectedPrewiew = blank_preparation_instructions?.text
        if(selectedPrewiew){
          const emptyArray = { variableArr: [], text: selectedPrewiew };
          HandelPreviewText(selectedTag, emptyArray, selectedPrewiew)
        }
      }
    }
  }, [formulaBuilderId, formulaDeatils])

  useEffect(() => {
    onChange(seletedVariavle)
    formValidation()
  }, [seletedVariavle])

  const HandelTags = (e) => {
    const array = allvarivale.find((item) => item.id == e)

    if (e != '' && e != 0 && array && array.lenght != 0) {
      const selectedValue = e
      array.tag_selected = e
      array.variableText = array.text

      setSeletedVariable(array)
      setselectedTag(e)
      setselectedVariables(array.variableArr)
      setselectedText(array.text)
      setselectedPreview(array.text)
      onChange(array)
    } else {
      setSeletedVariable([])
      setselectedTag(e)
      setselectedVariables([])
      setselectedText('')
      setselectedPreview('')
      onChange([])
    }

    // You can add additional logic here if needed
  }
  const HandelTagVariables = (rowNo, field, index, val, array) => {
    var finalText = array.variableText
    var variableArr = array.variableArr
    var previewText = array.text
    variableArr.map((v, k) => {
      if (v.field == field) {
        v.value = val
      }
      if (v.value) {
        var replaceField = v.replace
        finalText = finalText.replace(replaceField, v.value)
      }
    })

    array.variableArr = variableArr
    array.text = finalText
    setSeletedVariable(array)
    setselectedPreview(finalText)
    //setHerbTags(herbTags.map((val) => (val.rowNo == rowNo ? array : val)))
    //formValidation()
    onChange(array)
  }

  const formValidationM = () => {
    formValue['blank_preparation_instructions'] = seletedVariavle
    var Final_array = []
    Object.values(requirements).forEach((field) => {
      const ikey = field.key
      var i = field
      var isRequired = field.is_required == 1
      var fieldValue = formValue[ikey]

      if (ikey == 'blank_preparation_instructions') {
        fieldValue = formValue[ikey]['variableArr']
      }

      if (typeof fieldValue == 'object' && fieldValue.length == 0) {
        if (!formError[ikey]) {
          formError[ikey] = {} // Initialize if not defined
        }
        formError[ikey]['error'] = 'This field is required'
      } else if (typeof fieldValue == 'object' && fieldValue.length != 0) {
        if (!formError[ikey]) {
          formError[ikey] = {} // Initialize if not defined
        } else {
          //formError[ikey] = {}
        }
        formError[ikey]['error'] = ''
        fieldValue.forEach((item) => {
          const thisVal = String(item.value).trim()
          formError[ikey][item.id] =
            thisVal == '' ||
              thisVal == 0 ||
              thisVal == null ||
              thisVal == undefined
              ? 'This field is required'
              : ''
        })
      }

      const temp = {
        ...field,
        value: fieldValue, //formValue[ikey],
        error: formError[ikey],
      }
      Final_array.push(temp)
      //Final_array[ikey] = temp
    })
  }

  const HandelPreviewTextM = (rowNo, array=[], value) => {
    array.text = decodeEntities(value)
    setSeletedVariable(array)
    setselectedPreview(value)
  }
  const HandelPreviewText = (rowNo, array = [], value) => {
    if (array && array.text !== undefined) {
      array.text = decodeEntities(value);
      setSeletedVariable(array);
      setselectedPreview(value);
    } else {
      setselectedPreview(decodeEntities(value)); // Fallback if array is empty
    }
  };
  
  const formValidation = () => {
    formValue['blank_preparation_instructions'] = seletedVariavle
    formValue['blank_preparation_instructions_preview'] = selectedPreview
    var Final_array = []
    Object.values(requirements).forEach((field) => {
      const ikey = field.key
      const temp = {
        ...field,
        error: '',
        value: formValue[ikey],
      }
      Final_array.push(temp)
    })
    thisFormData(Final_array, 'FORMULAS_BLANK_PREPARATION_INSTRUCTIONS')
  }
  if (data.blank_preparation_instructions) {
    return (
      <>
        <div className='FormulaBuilderPreprationInstructionsSection'>
          <h2 className='title mt-5'>{display_name}</h2>
          <FormulaSectionTextBox data={formulaTextBox['PREPARATION_INSTRUCTIONS_BELOW']} dosageFormulaKey ='' />
          <div className='row'>
            <div className='col-8 '>
              <div className='col-12'  style={{ marginTop: '1rem' }}>
                <div className='row' style={{ marginBottom: '1rem' }}>
                  <SelectPicker
                    className='col-3'
                    placeholder={`Choose ${titleDisplayName}`}
                    cleanable={false}
                    searchable={true}
                    value={String(selectedTag)}
                    data={allvarivale}
                    labelKey='title'
                    valueKey='id'
                    onChange={(e, event) => {
                      HandelTags(e)
                    }}
                    renderMenuItem={(label, item) => (
                      <div style={fontFamily}>{label}</div>
                    )}
                  />
                  <div className='col-9 row'>
                    {selectedTag != '' &&
                      selectedTag != 0 &&
                      seletedVariavle?.variableArr?.map((option, index) => {
                        if (option) {
                          return (
                            <div className='col-2' key={index}>
                              <InputGroup>
                                <Input
                                  type='text'
                                  value={String(option.value)}
                                  placeholder={option.name}
                                  title={option.name}
                                  onChange={(e) => {
                                    HandelTagVariables(
                                      selectedTag,
                                      option.field,
                                      index,
                                      e, //.target.value,
                                      seletedVariavle
                                    )
                                  }}
                                  style={{ width: '100%' }}
                                />
                              </InputGroup>
                            </div>
                          )
                        }
                      })}
                  </div>
                  <br></br>
                  <Input
                    as='textarea'
                    rows={6}
                    //placeholder='Textarea'
                    value={decodeEntities(selectedPreview)}
                    //value={ he.decode(selectedPreview)}
                    className='col-12'
                    onChange={(e) => {
                      //HandelPreviewText(selectedTag, seletedVariavle, e)
                      if (seletedVariavle && seletedVariavle.length !== 0) {
                        HandelPreviewText(selectedTag, seletedVariavle, e);
                      } else {
                        const emptyArray = { variableArr: [], text: e };
                        HandelPreviewText(selectedTag, emptyArray, e);
                      }
                    }}
                    style={{
                      maxHeight: '15rem',
                      marginLeft: '0.8rem',
                      marginTop: '0.8rem',
                      border:'1px solid black'
                    }}
                    contentEditable={true}
                  />
                </div>

                {/* <div
                  className='col-12'
                  style={{
                    backgroundColor: 'white',
                    padding: '1rem',
                    border: '1px solid black',
                    marginTop: '1rem',
                    borderRadius: '6px',
                    overflow: 'hidden', // Add overflow: hidden to prevent overflow issues
                    marginLeft: '0.9rem',
                  }}
                  contentEditable={true}
                  dangerouslySetInnerHTML={{
                    __html: he.decode(selectedPreview),
                  }}
                ></div> */}

                <br></br>
              </div>
            </div>
            <div className='col-4 '>
            {/* <pre>{JSON.stringify(seletedVariavle, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}
