import React, { useEffect, useState, useMemo } from "react";
import { CheckPicker, Tooltip, Whisper, Popover } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";

function renderDynamicTable(
  data = [],
  columns = [],
  formulaIngredientsIdArr = []
) {
  if (!Array.isArray(data) || data.length === 0) {
    return <p>No Ingredients available</p>;
  }

  const sumByUnit = (data, key) =>
    data.reduce((acc, herb) => {
      acc[herb.unit] = (acc[herb.unit] || 0) + parseFloat(herb[key] || 0);
      return acc;
    }, {});

  const formatTotalsByUnit = (totals) =>
    Object.entries(totals)
      .map(([unit, total]) => `${total.toFixed(3)} ${unit}`)
      .join(" + ");

  const enterQtyColumn = columns.find(
    (col) => col.key === "FORMULA_DOSAGE_COLUMN_ENTER_QTY"
  );

  const rawPerDayColumn = columns.find(
    (col) => col.key === "FORMULA_DOSAGE_COLUMN_RAW_PER_DAY"
  );

  const showRawQtyColumn = !!rawPerDayColumn;
  const showEnterQtyColumn = !!enterQtyColumn;

  return (
    <div>
      <table className="table" style={{ fontSize: "10px" }}>
        <thead>
          <tr>
            <th>Ingredient Name</th>
            <th className="text-center">Ingredient Type</th>
            {showRawQtyColumn && (
              <th className="text-center">
                {rawPerDayColumn ? rawPerDayColumn.name : "Raw Quantity"}
              </th>
            )}
            {showEnterQtyColumn && (
              <th className="text-center">
                {enterQtyColumn ? enterQtyColumn.name : "Enter Quantity"}
              </th>
            )}
            <th className="text-center">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {data.map((herb) => (
            <tr key={herb?.herb_id}>
              <td>
                {herb.herb_name}
                {formulaIngredientsIdArr.includes(herb?.herb_id.toString()) ==
                  false && (
                  <span title="This Ingredient is not allowed to add">⚠️</span>
                )}
              </td>
              <td className="text-center">{herb.herb_type || ""}</td>
              {showRawQtyColumn && (
                <td className="text-center">
                  {parseFloat(herb.raw_qty || 0).toFixed(3)} {herb.unit}
                </td>
              )}
              {showEnterQtyColumn && (
                <td className="text-center">
                  {parseFloat(herb.enter_qty || 0).toFixed(3)} {herb.unit}
                </td>
              )}
              <td className="text-center">
                {parseFloat(herb.quantity || 0).toFixed(3)} {herb.unit}
              </td>
            </tr>
          ))}
        </tbody>
        {data.length > 0 && (
          <tfoot>
            <tr style={{ fontWeight: "bold" }}>
              <td colSpan="2">Total Ingredient: {data.length}</td>
              {showRawQtyColumn && (
                <td className="text-center">
                  {formatTotalsByUnit(sumByUnit(data, "raw_qty"))}
                </td>
              )}
              {showEnterQtyColumn && (
                <td className="text-center">
                  {formatTotalsByUnit(sumByUnit(data, "enter_qty"))}
                </td>
              )}
              <td className="text-center">
                {formatTotalsByUnit(sumByUnit(data, "quantity"))}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}

export const TabDropdown = ({
  name = "Formula",
  options = [],
  onChange,
  newTabData,
  onClose,
  onRemove,
  columns,
  formulaIngredientsIdArr = [],
  tabType = "",
  dropdownStyle,
  tabFormatting,
  hoverTab,
}) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const combinedStyle = isHovered
    ? { ...tabFormatting, ...hoverTab }
    : tabFormatting;
  useEffect(() => {
    setItems(options);
  }, [options]);
  /*
  const sortBySelected = useCallback((a, b) => {
    const isASelected = selected.includes(a.id);
    const isBSelected = selected.includes(b.id);

    if (isASelected && !isBSelected) return -1;
    if (!isASelected && isBSelected) return 1;
    return a.id - b.id; // Preserve initial order for unselected items
  }, [selected]);

  //const sortedData = [...options].sort(sortBySelected);
  const sortedData = [...options].sort(sortBySelected).map((item) => ({
    label: (
      <>
        {item.name}{' '}
        {item.total_herb !== 0 && (
          <span title={`${item.total_herb} Ingredients`}>
            ( {item.total_herb} Ingredients )
          </span>
        )}
      </>
    ),
    value: item.id,
    id: item.id,
    name: item.name,
    all_herbs: item.all_herbs,
    count_herbs: item.total_herb
  }));*/

  const sortedData = useMemo(() => {
    // Step 1: Sort options based on selected status and tabType
    const sortOptions = (a, b) => {
      const isASelected = selected.includes(a.id);
      const isBSelected = selected.includes(b.id);

      // Move selected items to the top
      if (isASelected && !isBSelected) return -1;
      if (!isASelected && isBSelected) return 1;

      // Apply tabType-specific sorting
      if (
        [
          "FORMULA_INGREDIENT_TAB_MY_FORMULAS",
          "FORMULA_INGREDIENT_TAB_PATIENT_FORMULAS",
        ].includes(tabType)
      ) {
        return Number(b.id) - Number(a.id); // Descending numerical order by id
      } else {
        return a.name.localeCompare(b.name); // Alphabetical order by name
      }
    };

    return [...options] .filter((item) => item.name.trim() != "") 
    .sort(sortOptions).map((item) => ({
      label: (
        <>
          {item.name}{" "}
          {item.total_herb !== 0 && (
            <span title={`${item.total_herb} Ingredients`}>
              ( {item.total_herb} Ingredients )
            </span>
          )}
        </>
      ),

      value: item.id,
      id: item.id,
      name: item.name,
      all_herbs: item.all_herbs,
      count_herbs: item.total_herb,
    }));
  }, [options, selected, tabType]); // Memoize based on options, selected, and tabType dependencies

  useEffect(() => {
    if (selected.length !== 0 && options.length > 0) {
      setItems(sortedData);
    }
  }, [selected, sortedData]);

  const handleSelectionChange = (newSelected) => {
    const addedItems = newSelected.filter((item) => !selected.includes(item));
    const removedItems = selected.filter((item) => !newSelected.includes(item));

    setSelected(newSelected);

    if (addedItems.length > 0) {
      newTabData(addedItems);
    } else if (removedItems.length > 0) {
      setRemoved((prev) => [...prev, ...removedItems]);
    }
  };

  const handleClose = () => {
    const allFormulas = [];
    const allFormulaHerbs = [];

    selected.forEach((formulaId) => {
      const formula = options.find((item) => item.id === formulaId);
      if (formula) {
        allFormulas.push(formula);
        formula.all_herbs.forEach((herb) => {
          herb.formula_id = formulaId;
          herb.formula_name = formula.name;
          allFormulaHerbs.push(herb);
        });
      }
    });

    const removedFormulaHerbs = removed.flatMap((formulaId) => {
      const formula = options.find((item) => item.id === formulaId);
      if (!formula || selected.includes(formulaId)) return [];
      return formula.all_herbs.map((herb) => ({
        ...herb,
        formula_id: formula.id,
        formula_name: formula.name,
      }));
    });

    if (allFormulas.length > 0) onClose(allFormulas, allFormulaHerbs);
    if (removedFormulaHerbs.length > 0) {
      onRemove(selected, removedFormulaHerbs);
      setRemoved([]);
    }
  };

  const renderMenu = (menu) => {
    if (options.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          No {name} available
        </p>
      );
    }
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }
    return menu;
  };

  const renderMenuItem = (label, item) => {
    const herbTable = renderDynamicTable(
      item.all_herbs,
      columns,
      formulaIngredientsIdArr
    );
    return (
      <Whisper
        placement="auto"
        followCursor
        speaker={<Popover style={dropdownStyle}>{herbTable}</Popover>}
      >
        <div style={{ ...dropdownStyle }}>{label}</div>
      </Whisper>
    );
  };

  return (
    <CheckPicker
      placement="auto"
      placeholder={
        <span
          style={{
            fontSize: combinedStyle.fontSize,
            color: combinedStyle.color,
          }}
        >
          {name}
        </span>
      }
      // placeholder={name}
      className="col-12 ingredientTabCheckbox"
      data={sortedData}
      renderMenu={renderMenu}
      renderMenuItem={renderMenuItem}
      value={selected}
      onChange={handleSelectionChange}
      cleanable={false}
      onClose={handleClose}
      style={combinedStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      renderValue={(value, items) => (
        <span className="rs-picker-toggle-value" style={{
          fontSize: combinedStyle.fontSize,
          color: combinedStyle.color,
        }}>
          <span
            className="rs-picker-value-list"
            title={items.map((item) => `${item.name} (${item.count_herbs} Ingredients)`).join(", ")}
          >
            {items.map((item, index) => (
              <>
                <span className="rs-picker-value-item">
                  {item.name} <span title={`${item.count_herbs} Ingredients`}>({item.count_herbs} Ingredients)</span>
                </span>
                {index < items.length - 1 && (
                  <span className="rs-picker-value-separator">,</span>
                )}
               </>
            ))}
          </span>
          {value.length > 0 && (
            <span
              className="rs-picker-value-count"
              title={value.length}
            >
              {value.length}
            </span>
          )}
        </span>
      )}
      
    />
  );
};
