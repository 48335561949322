import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { NoInternetPopup } from './helpers/system'

import './assets/app.css'
import storage from './storage'
import { Provider } from 'react-redux'

//import Header from './components/common/Header/Header'
//import Footer from './components/common/Footer/Footer'
import FormulaBuilder from './components/FormulaBuilder/FormulaBuilder'
import Test from './components/Test/Test'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <div className='App'>
          <NoInternetPopup />
          {/* <Header /> */}
          <Provider store={storage}>
            <Routes>              
              <Route path='/:id' element={<FormulaBuilder />} />
              <Route path="/test/:fId/:formulaT" element={<Test />} />              
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Provider>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    </>
  )
}

function NotFound() {
  return <h1 style={{ textAlign: 'center', marginTop: '6rem' }}>404 Not Found</h1>
}

export default App
